@import url("https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body {
  color: #000;
  font-family: "helvetica", Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  scroll-behavior: smooth;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

a {
  text-decoration: none;
  list-style: none;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
a:hover {
  text-decoration: none;
}

p {
  margin-bottom: 0;
}

button {
  outline: none;
  border: none;
}

button:hover,
button:focus {
  outline: none;
}

input,
input:focus {
  outline: none;
}

ul {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}
/* 
.container {
  max-width: 1120px;
} */
.section-title {
  font-family: "PT Serif", serif;
  font-weight: 700;
  font-size: 34px;
  line-height: 1.5;
  margin-bottom: 24px;
}
.section-text {
  font-family: "helvetica", Helvetica, sans-serif;
  font-size: 20px;
  line-height: 25px;
}

/*----- hero-switch -----*/
.hero-switch {
  display: inline-block;
  height: 26px;
  position: absolute;
  left: inherit;
  right: 48px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  width: 16px;
  z-index: 2;
}

.hero-switch input {
  display: none;
}

.hero-switch-slider {
  background-color: transparent;
  border: 1px solid #f4f4f4;
  cursor: pointer;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all 0.4s linear;
  -webkit-transition: all 0.4s linear;
  -moz-transition: all 0.4s linear;
  -ms-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
}

.hero-switch-slider:before {
  background-color: #fff;
  top: 2px;
  left: 2px;
  content: "";
  height: 10px;
  width: 10px;
  position: absolute;
  transition: all 0.4s linear;
  -webkit-transition: all 0.4s linear;
  -moz-transition: all 0.4s linear;
  -ms-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
}

input#checkbox:checked + .hero-switch-slider:before {
  transform: translateY(9px);
  -webkit-transform: translateY(9px);
  -moz-transform: translateY(9px);
  -ms-transform: translateY(9px);
  -o-transform: translateY(9px);
}

.hero-switch-slider.round {
  border-radius: 34px;
  -webkit-border-radius: 34px;
  -moz-border-radius: 34px;
  -ms-border-radius: 34px;
  -o-border-radius: 34px;
}

.hero-switch-slider.round:before {
  border-radius: 50%;
}
/*----- hero-switch -----*/

.blod-switch {
  display: inline-block;
  height: 54px;
  position: absolute;
  left: 0;
  right: 0;
  top: 40px;
  margin: 0 auto;
  width: 16px;
}

.blod-switch input {
  display: none;
}

.blod-switch-slider {
  display: inline-block;
  background-color: transparent;
  border: 1px solid #0d2240;
  cursor: pointer;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all 0.4s linear;
  -webkit-transition: all 0.4s linear;
  -moz-transition: all 0.4s linear;
  -ms-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
}

.blod-switch-slider:before {
  background-color: #0d2240;
  top: 2px;
  left: 2px;
  content: "";
  height: 10px;
  width: 10px;
  position: absolute;
  transition: all 0.4s linear;
  -webkit-transition: all 0.4s linear;
  -moz-transition: all 0.4s linear;
  -ms-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  animation: slidetobottom 1.5s linear infinite;
  -webkit-animation: slidetobottom 1.5s linear infinite;
}
@keyframes slidetobottom {
  0% {
    transform: translateY(0);
    -webkit-transform: translateY(0x);
    -moz-transform: translateY(0x);
    -ms-transform: translateY(0x);
    -o-transform: translateY(0x);
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
    transform: translateY(48px);
    -webkit-transform: translateY(48px);
    -moz-transform: translateY(48px);
    -ms-transform: translateY(48px);
    -o-transform: translateY(48px);
  }
}

/* input#checkboxTwo:checked + .blod-switch-slider:before {
  transform: translateY(9px);
  -webkit-transform: translateY(9px);
  -moz-transform: translateY(9px);
  -ms-transform: translateY(9px);
  -o-transform: translateY(9px);
} */

.blod-switch-slider.round {
  border-radius: 34px;
  -webkit-border-radius: 34px;
  -moz-border-radius: 34px;
  -ms-border-radius: 34px;
  -o-border-radius: 34px;
}

.blod-switch-slider.round:before {
  border-radius: 50%;
}

/*----- gk-accordion -----*/
.gk-accordion .card,
.gk-accordion .card h5 button,
.gk-accordion .card .card-body {
  border: none;
  background-color: #f4f4f4;
}
.gk-accordion .card .card-body,
.gk-accordion .card .card-header {
  background-color: #f4f4f4;
  border-bottom: none;
  transition: all 0.5s linear;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
}
.gk-accordion .card .card-body {
  padding: 0 10px 32px 0;

  color: #0d2240;
  font-family: "helvetica-light", sans-serif;
  font-size: 16px;
  line-height: 18px;
}
.gk-accordion .card .card-header,
.gk-accordion .card .card-header h5 {
  padding: 0;
}
.gk-accordion .card .card-header h5 {
  margin-bottom: 0;
}
.gk-accordion .card .card-header h5 button {
  width: 100%;
  padding: 16px 60px 4px 0;
  text-align: left;

  color: #0d2240;
  font-family: "PT Serif", serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  border-top: 1px solid #0d2240;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
}
.gk-accordion .card .card-header h5 button::before {
  content: "";
  width: 12px;
  height: 12px;
  background: url("./images/close-icon.svg") center no-repeat;
  position: absolute;
  top: 0;
  right: 38px;
  bottom: 0;
  margin: auto 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -ms-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
}
.gk-accordion .card .card-header h5 button[aria-expanded="false"] {
  padding-bottom: 16px;
}
.gk-accordion .card:last-child .card-header h5 button[aria-expanded="false"] {
  border-bottom: 1px solid #0d2240;
}
.gk-accordion .card:last-child .card-header h5 button[aria-expanded="true"] {
  border-bottom: none;
}
.gk-accordion .card .card-header h5 button[aria-expanded="true"] {
  padding-bottom: 10px;
}
.gk-accordion .card .card-header h5 button[aria-expanded="true"]::before {
  opacity: 1;
  visibility: visible;
}
/*----- gk-accordion -----*/

/* ---------- header ---------- */
.header {
  padding: 32px 0 16px;
  background-color: #020c1a;
}
.header .menu-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header .menu-bar .apply-card-btn {
  display: inline-block;
  padding: 8px 16px;
  color: #000000;
  font-family: "helvetica-light", Helvetica, sans-serif;
  font-size: 16px;
  line-height: 18px;
  background-color: #ebb700;
  border: 1px solid #ebb700;
  border-radius: 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  -o-border-radius: 24px;
}
.header .menu-bar .apply-card-btn:hover {
  background-color: transparent;
  color: #ebb700;
}

/* ---------- hero-section ---------- */
.hero-section {
  padding: 92px 0 64px;
  background: linear-gradient(180deg, rgba(2, 12, 26, 0.88) 0%, rgba(13, 34, 64, 0.88) 100%);
  backdrop-filter: blur(2px);
  overflow: hidden;
  position: relative;
}
.hero-section .container {
  position: relative;
  z-index: 1;
}
.hero-section .container::before {
  content: "";
  width: 1050px;
  height: 1050px;
  /* background: url("../images/hero-bg.png") center no-repeat; */
  background: radial-gradient(30.32% 30.32% at 51.06% 52.01%, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0) 100%);
  position: absolute;
  top: -45%;
  right: -20%;
  z-index: -1;
}
.hero-section .row {
  align-items: center;
}
.hero-section .hero-data {
  color: #ffffff;
}
.hero-section .hero-data h1 {
  max-width: 480px;
}
.hero-section .hero-data p {
  margin-bottom: 56px;
  max-width: 420px;
}
.hero-section .hero-data .apply-cc-btn {
  display: inline-block;
  padding: 7px 24px;
  color: #000000;
  font-family: "helvetica-light", Helvetica, sans-serif;
  font-size: 24px;
  line-height: 28px;
  background-color: #ebb700;
  border: 1px solid #ebb700;
  border-radius: 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  -o-border-radius: 24px;
}
.hero-section .hero-data .apply-cc-btn:hover {
  background-color: transparent;
  color: #ebb700;
}
.hero-section .hero-img {
  position: relative;
  display: flex;
  justify-content: center;
  padding-left: 50px;
  z-index: 2;
  -ms-perspective: 500px;
  perspective: 500px;
}
.hero-section .hero-img .iphone-frame {
  max-width: 337px;
}
.hero-section .hero-img .cc-card-img-wrap {
  min-width: 304px;
  min-height: 192px;
  position: absolute;
  right: 0;
  top: 45%;
  transition: all 0.5s linear;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;

  transform-style: preserve-3d;
}
.hero-section .hero-img .cc-card-img-wrap.flipped {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  /* animation: rotatecrd 1s linear;
  -webkit-animation: rotatecrd 1s linear; */
}
.hero-section .hero-img .cc-card-img-wrap img {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.hero-section .hero-img .cc-card-img-wrap .cc-card-one {
  position: absolute;
  top: 0;
  right: 0;
}
.hero-section .hero-img .cc-card-img-wrap .cc-card-two {
  position: absolute;
  top: 0;
  right: 0;
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
}
/* .hero-section .hero-img .cc-card-img-wrap.rotate .cc-card-two {
  z-index: 1;
} */
/* .hero-section .hero-img .cc-card-img-wrap.rotate .cc-card-one,
.hero-section .hero-img .cc-card-img-wrap.rotate .cc-card-two {
  transition: al 0.5s linear;
  -webkit-transition: al 0.5s linear;
  -moz-transition: al 0.5s linear;
  -ms-transition: al 0.5s linear;
  -o-transition: al 0.5s linear;
} */

/* ---------- your-journey ---------- */

.your-journey {
  padding: 220px 0 104px;
  position: relative;
}
.your-journey .yj-data h2 {
  color: #0d2240;
  max-width: 300px;
}
.your-journey .yj-data p {
  color: #0d2240;
  max-width: 416px;
}

.your-journey .yj-list .yj-item {
  color: #0d2240;
  font-family: "helvetica-light", Helvetica, sans-serif;
  font-size: 16px;
  line-height: 18px;
  padding-left: 30px;
  margin-bottom: 40px;
  opacity: 0.6;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
}
.your-journey .yj-list .yj-item:hover {
  opacity: 1;
}
.your-journey .yj-list .yj-item:last-child {
  margin-bottom: 0;
}
.your-journey .yj-list .yj-item h3::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #0d2240;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -50px;
  margin: auto 0;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.your-journey .yj-list .yj-item h3 {
  color: #0d2240;
  font-family: "PT Serif", serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  margin-bottom: 4px;
  position: relative;
}

/* ---------- the-benefits ---------- */

.the-benefits {
  background: linear-gradient(180deg, rgba(2, 12, 26, 0.88) 0%, rgba(13, 34, 64, 0.88) 100%);
  backdrop-filter: blur(2px);
  padding: 120px 0 170px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.the-benefits::before {
  content: "";
  width: 1440px;
  height: 1531px;
  background: radial-gradient(34.11% 34.11% at 51.06% 52.01%, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0) 100%);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  z-index: -1;
}
.the-benefits .the-benefits-title {
  color: #ffffff;
  max-width: 416px;
  margin-bottom: 80px;
}
.the-benefits .benefits-col {
  display: flex;
  justify-content: center;
}
.the-benefits .benefits-list .benefits-item {
  position: relative;
  margin-bottom: -150px;
}
.the-benefits .benefits-list .benefits-item:last-child {
  position: relative;
  margin-bottom: 0;
}
.the-benefits .benefits-list .benefits-item.card-one .card-img-wrap {
  position: relative;
  z-index: 3;
}
.the-benefits .benefits-list .benefits-item.card-two .card-img-wrap {
  position: relative;
  z-index: 2;
}
.the-benefits .benefits-list .benefits-item.card-three .card-img-wrap {
  position: relative;
  z-index: 1;
}
.the-benefits .benefits-list .benefits-item.card-four .card-img-wrap {
  position: relative;
  z-index: -1;
}
.the-benefits .benefits-list .benefits-item.card-four .card-img-wrap {
  top: 0 !important;
}
.the-benefits .benefits-list .benefits-item .card-benefits {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  max-width: 303px;
  color: #ffffff;
  font-family: "helvetica-light", Helvetica, sans-serif;
  font-size: 16px;
  line-height: 18px;
  opacity: 0.6;
  z-index: 9;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
}
/* .the-benefits .benefits-list .card-benefits p {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
} */
.the-benefits .benefits-list .benefits-item .card-benefits:hover {
  opacity: 1;
}
.the-benefits .benefits-list .benefits-item.card-three .card-benefits,
.the-benefits .benefits-list .benefits-item.card-one .card-benefits {
  top: 28%;
  right: -80%;
}
.the-benefits .benefits-list .benefits-item.card-four .card-benefits,
.the-benefits .benefits-list .benefits-item.card-two .card-benefits {
  top: 46%;
  left: -80%;
}

.the-benefits .benefits-list {
  position: relative;
  display: flex;
  align-items: center;
}
.the-benefits .benefits-list .card-img-wrap {
  position: relative;
}
.the-benefits .benefits-list .card-benefits {
  cursor: pointer;
}
.the-benefits .benefits-list .card-benefits h4 {
  color: #ffffff;
  font-family: "PT Serif", serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 4px;
}

.the-benefits .benefits-list .card-benefits p {
  color: #ffffff;
  font-family: "PT Serif", serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  margin-bottom: 4px;
}

.the-benefits .benefits-list .card-benefits {
  padding: 10px 0;
}

.english .first-hoverimg:after,
.english .second-hoverimg:after,
.english .third-hoverimg:after,
.english .forth-hoverimg:after {
  content: "";
  background: #000000;
  width: 50%;
  height: 20%;
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 120px;
  transform: skewX(350deg);
  filter: blur(43px);
  z-index: -1;
}
/* 
.first-img {
  position: absolute;
  opacity: 0.8;
  bottom: -525px;
  left: 0;
  right: 0;
  z-index: 9;
  transition: 0.5s all;
}
.first-hoverimg {
  opacity: 1;
  bottom: -100px;
  transition: 0.5s all;
  z-index: 9;
}

.second-img {
  position: absolute;
  opacity: 0.7;
  bottom: -410px;
  left: 0;
  right: 0;
  z-index: 8;
  transition: 0.5s all;
}
.second-hoverimg {
  opacity: 1;
  bottom: -90px;
  transition: 0.5s all;
  z-index: 7;
}

.third-img {
  position: absolute;
  opacity: 0.6;
  bottom: -295px;
  left: 0;
  right: 0;
  z-index: 7;
  transition: 0.5s all;
}
.third-hoverimg {
  opacity: 1;
  bottom: -81px;
  transition: 0.5s all;
  z-index: 6;
}

.forth-img {
  position: absolute;
  opacity: 0.7;
  bottom: -180px;
  left: 0;
  right: 0;
  z-index: 6;
  transition: 0.5s all;
}
.forth-hoverimg {
  opacity: 1;
  bottom: -52px;
  transition: 0.5s all;
  z-index: 5;
} */
.english .first-img {
  position: absolute;
  opacity: 0.8;
  bottom: -499px;
  left: 0;
  right: 0;
  z-index: 10;
  transition: 0.5s all;
}

.english .second-img {
  position: absolute;
  opacity: 0.7;
  bottom: -412px;
  left: 0;
  right: 0;
  z-index: 7;
  transition: 0.5s all;
}

.english .third-img {
  position: absolute;
  opacity: 0.6;
  bottom: -262px;
  left: 0;
  right: 0;
  z-index: 8;
  transition: 0.5s all;
}

.english .forth-img {
  position: absolute;
  opacity: 0.7;
  bottom: -142px;
  left: 0;
  right: 0;
  z-index: 6;
  transition: 0.5s all;
}

.english .first-hoverimg {
  opacity: 1;
  bottom: -130px;
  transition: 0.5s all;
  z-index: 13;
}
.english .second-hoverimg {
  opacity: 1;
  bottom: -134px;
  transition: 0.5s all;
  z-index: 12;
}
.english .third-hoverimg {
  opacity: 1;
  bottom: -105px;
  transition: 0.5s all;
  z-index: 11;
}
.english .forth-hoverimg {
  opacity: 1;
  bottom: -70px;
  transition: 0.5s all;
  z-index: 10;
}
/* French */
.the-benefits .benefits-list.french .card-img-wrap {
  position: relative;
  padding-top: 50px;
}
.french .first-hoverimg:after,
.french .second-hoverimg:after,
.french .third-hoverimg:after,
.french .forth-hoverimg:after {
  content: "";
  background: #000000;
  width: 50%;
  height: 20%;
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 120px;
  transform: skewX(350deg);
  filter: blur(43px);
  z-index: -1;
}
.french .first-img {
  position: absolute;
  opacity: 0.8;
  bottom: -544px;
  left: 0;
  right: 0;
  z-index: 6;
  transition: 0.5s all;
}

.french .second-img {
  position: absolute;
  opacity: 0.7;
  bottom: -441px;
  left: 0;
  right: 0;
  z-index: 7;
  transition: 0.5s all;
}

.french .third-img {
  position: absolute;
  opacity: 0.6;
  bottom: -321px;
  left: 0;
  right: 0;
  z-index: 8;
  transition: 0.5s all;
}

.french .forth-img {
  position: absolute;
  opacity: 0.7;
  bottom: -153px;
  left: 0;
  right: 0;
  z-index: 9;
  transition: 0.5s all;
}

.french .first-hoverimg {
  opacity: 1;
  bottom: 234px;
  transition: 0.5s all;
  z-index: 13;
}
.french .second-hoverimg {
  opacity: 1;
  bottom: -14px;
  transition: 0.5s all;
  z-index: 12;
}
.french .third-hoverimg {
  opacity: 1;
  bottom: -214px;
  transition: 0.5s all;
  z-index: 11;
}
.french .forth-hoverimg {
  opacity: 1;
  bottom: -400px;
  transition: 0.5s all;
  z-index: 10;
}
/* German */

.german .first-hoverimg:after,
.german .second-hoverimg:after,
.german .third-hoverimg:after,
.german .forth-hoverimg:after {
  content: "";
  background: #000000;
  width: 50%;
  height: 20%;
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 120px;
  transform: skewX(350deg);
  filter: blur(43px);
  z-index: -1;
}
.german .first-img {
  position: absolute;
  opacity: 0.8;
  bottom: -592px;
  left: 0;
  right: 0;
  z-index: 6;
  transition: 0.5s all;
}

.german .second-img {
  position: absolute;
  opacity: 0.7;
  bottom: -473px;
  left: 0;
  right: 0;
  z-index: 7;
  transition: 0.5s all;
}

.german .third-img {
  position: absolute;
  opacity: 0.6;
  bottom: -309px;
  left: 0;
  right: 0;
  z-index: 8;
  transition: 0.5s all;
}

.german .forth-img {
  position: absolute;
  opacity: 0.7;
  bottom: -112px;
  left: 0;
  right: 0;
  z-index: 9;
  transition: 0.5s all;
}

.german .first-hoverimg {
  opacity: 1;
  bottom: 304px;
  transition: 0.5s all;
  z-index: 13;
}
.german .second-hoverimg {
  opacity: 1;
  bottom: -4px;
  transition: 0.5s all;
  z-index: 12;
}
.german .third-hoverimg {
  opacity: 1;
  bottom: -284px;
  transition: 0.5s all;
  z-index: 11;
}
.german .forth-hoverimg {
  opacity: 1;
  bottom: -500px;
  transition: 0.5s all;
  z-index: 10;
}

/* italian */

.italian .first-hoverimg:after,
.italian .second-hoverimg:after,
.italian .third-hoverimg:after,
.italian .forth-hoverimg:after {
  content: "";
  background: #000000;
  width: 50%;
  height: 20%;
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 120px;
  transform: skewX(350deg);
  filter: blur(43px);
  z-index: -1;
}
.italian .first-img {
  position: absolute;
  opacity: 0.8;
  bottom: -544px;
  left: 0;
  right: 0;
  z-index: 6;
  transition: 0.5s all;
}

.italian .second-img {
  position: absolute;
  opacity: 0.7;
  bottom: -441px;
  left: 0;
  right: 0;
  z-index: 7;
  transition: 0.5s all;
}

.italian .third-img {
  position: absolute;
  opacity: 0.6;
  bottom: -321px;
  left: 0;
  right: 0;
  z-index: 8;
  transition: 0.5s all;
}

.italian .forth-img {
  position: absolute;
  opacity: 0.7;
  bottom: -153px;
  left: 0;
  right: 0;
  z-index: 9;
  transition: 0.5s all;
}

.italian .first-hoverimg {
  opacity: 1;
  bottom: 234px;
  transition: 0.5s all;
  z-index: 13;
}
.italian .second-hoverimg {
  opacity: 1;
  bottom: -14px;
  transition: 0.5s all;
  z-index: 12;
}
.italian .third-hoverimg {
  opacity: 1;
  bottom: -214px;
  transition: 0.5s all;
  z-index: 11;
}
.italian .forth-hoverimg {
  opacity: 1;
  bottom: -400px;
  transition: 0.5s all;
  z-index: 10;
}

/* ---------- good-to-know ---------- */
.good-to-know {
  background-color: #f4f4f4;
  padding-top: 120px;
  position: relative;
  z-index: 1;
}
.good-to-know .good-know-data {
  color: #0d2240;
}
.good-to-know .good-know-data p {
  max-width: 416px;
}
.good-to-know .row:first-child {
  margin-bottom: 73px;
}
.good-to-know .row:last-child {
  margin-bottom: 0px;
}
.good-to-know .call-action-wrap {
  background-color: #ebb700;

  /* max-width: 1110px; */
  padding: 60px 30px;
  margin: 0 auto -40px;

  border-radius: 14px;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  -ms-border-radius: 14px;
  -o-border-radius: 14px;
}
.good-to-know .call-action-card {
  /* display: flex;
  align-items: flex-end; */
  position: relative;
  z-index: 1;
  min-height: auto;
  /* margin-right: 67px; */
}
.good-to-know .call-action-card .club-card-one {
  position: absolute;
  top: 10px;
  left: 30px;
  z-index: -1;
}
.good-to-know .call-action-datat {
  margin-left: 67px;
  max-width: 416px;
  color: #0d2240;
  line-height: 23px;
}
.good-to-know .call-action-datat p {
  margin-bottom: 24px;
  line-height: 1.5;
}
.good-to-know .call-action-datat .apply-crad-btn {
  display: inline-block;
  padding: 7px 16px 7px;
  background-color: #0d2240;
  border: 1px solid #0d2240;
  color: #ffffff;
  font-family: "helvetica-light", Helvetica, sans-serif;
  font-size: 16px;
  line-height: 18px;
  border-radius: 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  -o-border-radius: 24px;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
}
.good-to-know .call-action-datat .apply-crad-btn:hover {
  background-color: transparent;
  color: #0d2240;
}
/* ---------- good-to-know ---------- */

/* ---------- footer ---------- */

.footer {
  padding: 120px 0 40px;
  background-color: #0d2240;
}
.footer .footer-content {
  color: #ffffff;
  font-family: "helvetica-light", Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
}
.footer .footer-content .f-logo {
  display: inline-block;
  max-width: 425px;
  margin-bottom: 40px;
}

.footer .footer-content p {
  max-width: 194px;
  padding-bottom: 40px;
  margin-bottom: 0;
  line-height: 1.8;
  font-size: 19px;
}
.footer .footer-content .f-list {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}
.footer .footer-content .f-link a {
  margin-right: 16px;
  color: #ffffff;
  text-decoration: none;
}
.f-lang button {
  margin-left: 16px;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}
.tc {
  display: flex;
  justify-content: space-between;
}
.yj-list li {
  cursor: pointer;
}
.gk-accordion .card-header button {
  cursor: pointer;
}
/* .benefits-item .card-benefits h4,
p {
  cursor: pointer;
} */

.card-one #card-img-one,
.card-two #card-img-two,
.card-three #card-img-three,
.card-four #card-img-four {
  transition: all 0.5s ease !important;
}

.card-one:hover #card-img-one {
  top: 30px !important;
  transform: translatex(50px);
}

.card-two:hover #card-img-two {
  top: 30px !important;
  transform: translatex(-50px);
}

.card-three:hover #card-img-three {
  top: 10px !important;
  transform: translatex(50px);
}

.card-four:hover #card-img-four {
  top: 30px !important;
  transform: translatex(-50px);
}

.card_ {
  animation-duration: 3s;
  animation-fill-mode: both;
  animation-name: fadeInBottom;
}

@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
  }
}

/*----- gk-accordion -----*/
@media (max-width: 1279px) {
  .hero-switch {
    left: inherit;
    right: 10px;
  }
  .hero-section .hero-img {
    padding-left: 0;
  }
  .hero-section .hero-img .cc-card-img-wrap {
    right: 20px;
  }
  .your-journey {
    padding: 150px 0 90px;
  }
  .the-benefits {
    padding: 90px 0 120px;
  }
  .the-benefits::before {
    width: auto;
    height: auto;
  }
  .good-to-know {
    padding-top: 90px;
  }
  .good-to-know .row:first-child {
    margin-bottom: 90px;
  }
  .footer {
    padding: 100px 0 40px;
  }
}
@media (max-width: 1199px) {
  .good-to-know .call-action-card {
    position: relative;
    z-index: 1;
    min-height: auto;
    /* margin-right: 67px; */
  }
  .the-benefits .benefits-list .benefits-item {
    margin-bottom: -130px;
  }
  .the-benefits .benefits-list .benefits-item .card-img-wrap {
    max-width: 350px;
  }
  .the-benefits .benefits-list .benefits-item .card-benefits {
    max-width: 290px;
  }
  .the-benefits .benefits-list .benefits-item.card-three .card-benefits,
  .the-benefits .benefits-list .benefits-item.card-one .card-benefits {
    top: 20%;
    right: -90%;
  }
  .the-benefits .benefits-list .benefits-item.card-four .card-benefits,
  .the-benefits .benefits-list .benefits-item.card-two .card-benefits {
    top: 40%;
    left: -85%;
  }
  .good-to-know .call-action-wrap {
    padding: 50px 30px 50px 60px;
  }
  /* .good-to-know .call-action-card {
    margin-right: 50px;
  } */
  .good-to-know .call-action-datat {
    max-width: 100%;
    padding-top: 20px;
    margin-left: 0px;
  }
}
@media (max-width: 991px) {
  .section-title {
    font-size: 35px;
    line-height: 45px;
    margin-bottom: 15px;
  }
  .section-text {
    font-size: 18px;
    line-height: 24px;
  }

  .hero-section {
    padding: 70px 0 64px;
  }
  .hero-section .hero-data {
    padding-left: 25px;
  }
  .hero-section .hero-data p {
    margin-bottom: 35px;
  }
  .hero-section .hero-data .apply-cc-btn {
    font-size: 18px;
    line-height: 28px;
  }
  .hero-section .hero-img .iphone-frame {
    max-width: 250px;
  }
  .hero-section .hero-img .cc-card-img-wrap {
    min-width: 250px;
    min-height: 158px;
    right: 0px;
    top: 45%;
  }
  .your-journey {
    padding: 90px 0 60px;
  }
  .blod-switch {
    top: 10px;
  }
  .your-journey .yj-list .yj-item {
    padding-left: 30px;
    margin-bottom: 20px;
  }
  .your-journey .yj-list .yj-item h3 {
    font-size: 25px;
    line-height: 35px;
  }
  .the-benefits {
    padding: 60px 0 90px;
  }
  .the-benefits .the-benefits-title {
    max-width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }
  .the-benefits .benefits-list .benefits-item {
    margin-bottom: -85px;
  }
  .the-benefits .benefits-list .benefits-item .card-img-wrap {
    max-width: 260px;
  }
  .the-benefits .benefits-list .benefits-item .card-benefits {
    font-size: 15px;
    line-height: 20px;
    max-width: 230px;
  }
  .the-benefits .benefits-list .benefits-item.card-three .card-benefits,
  .the-benefits .benefits-list .benefits-item.card-one .card-benefits {
    top: 20%;
    right: -93%;
  }
  .the-benefits .benefits-list .benefits-item.card-four .card-benefits,
  .the-benefits .benefits-list .benefits-item.card-two .card-benefits {
    top: 40%;
    left: -92%;
  }

  .the-benefits .benefits-list .benefits-item .card-benefits h4 {
    font-size: 20px;
    line-height: 30px;
  }
  .good-to-know {
    padding-top: 70px;
  }
  .gk-accordion .card .card-header h5 button {
    font-size: 20px;
    line-height: 28px;
  }
  .good-to-know .row:first-child {
    margin-bottom: 60px;
  }
  .good-to-know .call-action-wrap {
    padding: 50px 10px 30px 20px;
    align-items: flex-end;
  }
  .good-to-know .call-action-card {
    margin-right: 25px;
    padding-top: 20px;
    align-items: center;
    min-height: auto;
  }
  .good-to-know .call-action-card .club-card-one {
    top: -20px;
    left: 20px;
  }
  /* .good-to-know .call-action-datat {
    margin-left: 25px;
    max-width: 400px;
  } */

  .footer {
    padding: 80px 0 40px;
  }
  .footer .footer-content p {
    margin-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .header .menu-bar .apply-card-btn {
    display: none;
  }
  /* .benefits-direction {
    flex-direction: column-reverse;
    display: flex;
  } */
  .section-title {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 10px;
  }
  .section-text {
    font-size: 16px;
    line-height: 22px;
  }

  .header {
    padding: 16px 0 16px;
  }
  .header .menu-bar .apply-card-btn {
    padding: 5px 12px;
    font-size: 14px;
    line-height: 18px;
  }
  .hero-section {
    padding: 40px 0 40px;
  }
  .hero-switch {
    top: -25%;
  }
  .hero-section .hero-img .cc-card-img-wrap {
    right: 20%;
  }

  .hero-section .row {
    flex-direction: column-reverse;
  }
  .hero-section .hero-img {
    margin-bottom: 40px;
  }
  .hero-section .hero-img .iphone-frame {
    max-width: 230px;
  }
  .hero-section .hero-img .cc-card-img-wrap {
    min-width: 230px;
    min-height: 146px;
  }
  .hero-section .container::before {
    top: -55%;
    right: -35%;
  }

  .hero-section .hero-data {
    padding-left: 0px;
  }
  .hero-section .hero-data p,
  .hero-section .hero-data h1 {
    max-width: 100%;
  }
  .hero-section .hero-data .apply-cc-btn {
    font-size: 16px;
    line-height: 26px;
  }
  .your-journey {
    padding: 60px 0 30px;
  }
  .your-journey .yj-data {
    margin-bottom: 30px;
  }
  .blod-switch {
    height: 40px;
  }
  @keyframes slidetobottom {
    0% {
      transform: translateY(0);
      -webkit-transform: translateY(0x);
      -moz-transform: translateY(0x);
      -ms-transform: translateY(0x);
      -o-transform: translateY(0x);
    }
    50% {
      opacity: 0.7;
    }
    100% {
      opacity: 0;
      transform: translateY(31px);
      -webkit-transform: translateY(31px);
      -moz-transform: translateY(31px);
      -ms-transform: translateY(31px);
      -o-transform: translateY(31px);
    }
  }
  .your-journey .yj-data p,
  .your-journey .yj-data h2 {
    max-width: 100%;
  }
  .your-journey .yj-list .yj-item h3::before {
    left: -30px;
    width: 8px;
    height: 8px;
  }
  .good-to-know {
    padding-top: 30px;
  }
  .good-to-know .row:first-child {
    margin-bottom: 40px;
  }
  .good-know-data {
    margin-bottom: 30px;
  }
  .good-to-know .call-action-wrap {
    flex-direction: column;
    align-items: center;
    padding: 50px 20px 30px 20px;
  }
  .good-to-know .call-action-card {
    padding-right: 0px;
    margin: 0 auto 30px;
  }
  .good-to-know .call-action-datat {
    margin-left: 0;
    max-width: 325px;
  }
  .gk-accordion .card .card-header h5 button {
    font-size: 18px;
    line-height: 26px;
    padding: 10px 30px 4px 0px;
  }
  .gk-accordion .card .card-body {
    padding: 0 10px 20px 0;
  }
  .gk-accordion .card .card-header h5 button[aria-expanded="false"] {
    padding-bottom: 10px;
  }
  .gk-accordion .card .card-header h5 button::before {
    right: 10px;
  }
  .the-benefits {
    padding: 40px 0 75px;
  }
  .footer .footer-content .f-logo {
    max-width: 350px;
  }
  .footer .footer-content p {
    margin-bottom: 40px;
  }
}
@media (max-width: 576px) {
  .tc {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer .footer-content p {
    margin-bottom: 0;
  }
  .footer .footer-content {
    text-align: center;
  }
  .footer .footer-content p {
    max-width: 310px;
    margin: 0 auto;
  }
  .hero-section .hero-img .cc-card-img-wrap {
    right: 15%;
  }
  .hero-section .container::before {
    top: -45%;
    right: -40%;
  }
  .footer .footer-content .f-link a,
  .f-lang button {
    margin: 20px 10px 0;
    color: #ffffff;
    text-decoration: none;
  }
}
@media (max-width: 479px) {
  .section-title {
    font-size: 28px;
    line-height: 38px;
  }
  .header .brand-logo {
    max-width: 170px;
  }
  .header .menu-bar .apply-card-btn {
    padding: 5px 12px;
    font-size: 12px;
    line-height: 16px;
  }

  .hero-section .hero-img .iphone-frame {
    max-width: 200px;
  }
  .hero-section .hero-img .cc-card-img-wrap {
    min-width: 200px;
    min-height: 127px;
    right: 10%;
  }
  .hero-section .hero-data p {
    margin-bottom: 20px;
  }
  .hero-section .hero-data .apply-cc-btn {
    display: inline-block;
    padding: 5px 20px;
  }
  .your-journey {
    padding: 60px 0 40px;
  }
  .your-journey .yj-list .yj-item h3 {
    font-size: 20px;
    line-height: 30px;
  }
  .your-journey .yj-list .yj-item h3::before {
    width: 7px;
    height: 7px;
  }
  .your-journey .yj-list .yj-item {
    margin-bottom: 15px;
  }
  .the-benefits {
    padding: 30px 0 50px;
  }
  .the-benefits .benefits-col {
    display: flex;
    justify-content: flex-start;
  }
  .the-benefits .benefits-list .benefits-item.card-one .card-benefits {
    top: -50%;
    right: -128%;
  }
  .the-benefits .benefits-list .benefits-item.card-two .card-benefits {
    top: -20%;
    right: -128%;
    left: auto;
  }
  .the-benefits .benefits-list .benefits-item.card-three .card-benefits {
    top: -30%;
    right: -128%;
  }
  .the-benefits .benefits-list .benefits-item.card-four .card-benefits {
    top: 80%;
    right: -128%;
    left: auto;
  }
  .the-benefits .benefits-list .benefits-item .card-img-wrap {
    max-width: 140px;
  }
  .the-benefits .benefits-list .benefits-item .card-benefits h4 {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 2px;
  }
  .the-benefits .benefits-list .benefits-item .card-benefits {
    font-size: 10px;
    line-height: 12px;
    max-width: 160px;
    top: -20%;
    right: -128%;
  }
  .the-benefits .benefits-list .benefits-item {
    margin-bottom: -25px;
  }
  .good-to-know .row:first-child {
    margin-bottom: 30px;
  }
  .good-know-data {
    margin-bottom: 25px;
  }
  .good-to-know .call-cc-wrap {
    padding-right: 20px;
  }
  .good-to-know .call-action-wrap {
    padding: 50px 15px 25px 15px;
  }
  .good-to-know .call-action-card {
    margin-bottom: 0px;
    padding-right: 0;
  }
  .good-to-know .call-action-card .club-card-one {
    left: 0px;
    top: 0;
    bottom: 0;
    right: 0;
    min-width: 100%;
  }
  .good-to-know .call-action-datat p {
    margin-bottom: 15px;
  }
  .footer .footer-content .f-logo {
    max-width: 250px;
    margin-bottom: 30px;
  }
  .footer .footer-content p {
    margin-bottom: 30px;
  }
}
@media (max-width: 359px) {
  .hero-section .hero-img .cc-card-img-wrap {
    right: 0;
  }
  .the-benefits .benefits-list .benefits-item .card-img-wrap {
    max-width: 130px;
  }
}

.languagebutton {
  background-color: transparent;
  color: white;
}

.hero-switch-css {
  margin-top: 0px !important;
}

@media (max-width: 576px) {
  .hero-switch-css {
    margin-top: 48px !important;
  }
}

.flip-card {
  width: 100%;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
}

.flip-card:hover .flip-card-inner {
  /* transform: rotateY(180deg); */
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-back {
  transform: rotateY(180deg);
}

/*accordian css old...*/

.gk-accordion .accordion-item h2 button {
  font-size: 20px;
  line-height: 28px;
}
.gk-accordion .accordion-item h2 button {
  font-size: 18px;
  line-height: 26px;
  padding: 10px 30px 4px 0px;
}
.gk-accordion .accordion-item .accordion-body {
  padding: 0 10px 20px 0;
}
.gk-accordion .accordion-item h2 button[aria-expanded="false"] {
  padding-bottom: 10px;
}
.gk-accordion .accordion-item h2 button::before {
  right: 10px;
}
.the-benefits .benefits-list .benefits-item {
  margin-bottom: -55px;
}
.the-benefits .benefits-list .benefits-item .card-img-wrap {
  max-width: 200px;
}
.the-benefits .benefits-list .benefits-item .card-benefits h4 {
  font-size: 17px;
  line-height: 23px;
}
.the-benefits .benefits-list .benefits-item .card-benefits {
  font-size: 13px;
  line-height: 18px;
  max-width: 170px;
  height: max-content;
}
.the-benefits .benefits-list .benefits-item.card-three .card-benefits,
.the-benefits .benefits-list .benefits-item.card-one .card-benefits {
  top: 20%;
  right: -90%;
}
.the-benefits .benefits-list .benefits-item.card-four .card-benefits,
.the-benefits .benefits-list .benefits-item.card-two .card-benefits {
  top: 40%;
  left: -88%;
}
.the-benefits .benefits-col {
  display: flex;
  justify-content: flex-start;
}
.the-benefits .benefits-list .benefits-item {
  margin-bottom: -55px;
}
.the-benefits .benefits-list .benefits-item .card-img-wrap {
  max-width: 180px;
}
.the-benefits .benefits-list .benefits-item .card-benefits {
  font-size: 12px;
  line-height: 14px;
  height: max-content;
  max-width: 220px;
}
.the-benefits .benefits-list .benefits-item.card-one .card-benefits {
  top: -50%;
  right: -136%;
}
.the-benefits .benefits-list .benefits-item.card-two .card-benefits {
  top: -20%;
  right: -136%;
  left: auto;
}
.the-benefits .benefits-list .benefits-item.card-three .card-benefits {
  top: 30%;
  right: -136%;
}
.the-benefits .benefits-list .benefits-item.card-four .card-benefits {
  top: 80%;
  right: -136%;
  left: auto;
}
.the-benefits .benefits-list .benefits-item .card-benefits h4 {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 2px;
}
.the-benefits .benefits-list .benefits-item .card-img-wrap {
  max-width: 130px;
}
.gk-accordion.accordion {
  --bs-accordion-bg: transparent;
}
.card-one #card-img-one,
.card-two #card-img-two,
.card-three #card-img-three,
.card-four #card-img-four {
  transition: all 0.5s ease !important;
}
.card-one:hover #card-img-one {
  top: 30px !important;
  transform: translatex(50px);
}
.card-two:hover #card-img-two {
  top: 30px !important;
  transform: translatex(-50px);
}
.card-three:hover #card-img-three {
  top: 10px !important;
  transform: translatex(50px);
}
.card-four:hover #card-img-four {
  top: 30px !important;
  transform: translatex(-50px);
}
.card_ {
  animation-duration: 3s;
  animation-fill-mode: both;
  animation-name: fadeInBottom;
}
@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
  }
}
.languagebutton {
  background-color: transparent;
  color: white;
}
.hero-switch-css {
  margin-top: 0px !important;
}

@media screen and (max-width: 1366px) {
  .english .first-hoverimg:after,
  .english .second-hoverimg:after,
  .english .third-hoverimg:after,
  .english .forth-hoverimg:after {
    content: "";
    background: #000000;
    width: 50%;
    height: 20%;
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 120px;
    transform: skewX(350deg);
    filter: blur(43px);
    z-index: -1;
  }

  .english .first-img {
    position: absolute;
    opacity: 0.8;
    bottom: -499px;
    left: 0;
    right: 0;
    z-index: 10;
    transition: 0.5s all;
  }
  
  .english .second-img {
    position: absolute;
    opacity: 0.7;
    bottom: -412px;
    left: 0;
    right: 0;
    z-index: 7;
    transition: 0.5s all;
  }
  
  .english .third-img {
    position: absolute;
    opacity: 0.6;
    bottom: -262px;
    left: 0;
    right: 0;
    z-index: 8;
    transition: 0.5s all;
  }
  
  .english .forth-img {
    position: absolute;
    opacity: 0.7;
    bottom: -110px;
    left: 0;
    right: 0;
    z-index: 6;
    transition: 0.5s all;
  }
  
  .english .first-hoverimg {
    opacity: 1;
    bottom: -130px;
    transition: 0.5s all;
    z-index: 13;
  }
  .english .second-hoverimg {
    opacity: 1;
    bottom: -134px;
    transition: 0.5s all;
    z-index: 12;
  }
  .english .third-hoverimg {
    opacity: 1;
    bottom: -105px;
    transition: 0.5s all;
    z-index: 11;
  }
  .english .forth-hoverimg {
    opacity: 1;
    bottom: -40px;
    transition: 0.5s all;
    z-index: 10;
  }
  /* French */

  .french .first-hoverimg:after,
  .french .second-hoverimg:after,
  .french .third-hoverimg:after,
  .french .forth-hoverimg:after {
    content: "";
    background: #000000;
    width: 50%;
    height: 20%;
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 120px;
    transform: skewX(350deg);
    filter: blur(43px);
    z-index: -1;
  }

  
  .french .first-img {
    position: absolute;
    opacity: 0.8;
    bottom: -524px;
    left: 0;
    right: 0;
    z-index: 10;
    transition: 0.5s all;
  }

  .french .second-img {
    position: absolute;
    opacity: 0.7;
    bottom: -426px;
    left: 0;
    right: 0;
    z-index: 9;
    transition: 0.5s all;
  }

  .french .third-img {
    position: absolute;
    opacity: 0.6;
    bottom: -298px;
    left: 0;
    right: 0;
    z-index: 8;
    transition: 0.5s all;
  }

  .french .forth-img {
    position: absolute;
    opacity: 0.7;
    bottom: -137px;
    left: 0;
    right: 0;
    z-index: 7;
    transition: 0.5s all;
  }

  .french .first-hoverimg {
    opacity: 1;
    bottom: -129px;
    transition: 0.5s all;
    z-index: 13;
  }
  .french .second-hoverimg {
    opacity: 1;
    bottom: -128px;
    transition: 0.5s all;
    z-index: 12;
  }
  .french .third-hoverimg {
    opacity: 1;
    bottom: -103px;
    transition: 0.5s all;
    z-index: 11;
  }
  .french .forth-hoverimg {
    opacity: 1;
    bottom: -43px;
    transition: 0.5s all;
    z-index: 10;
  }
  /* German */

  .german .first-hoverimg:after,
  .german .second-hoverimg:after,
  .german .third-hoverimg:after,
  .german .forth-hoverimg:after {
    content: "";
    background: #000000;
    width: 50%;
    height: 20%;
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 120px;
    transform: skewX(350deg);
    filter: blur(43px);
    z-index: -1;
  }
  .german .first-img {
    position: absolute;
    opacity: 0.8;
    bottom: -595px;
    left: 0;
    right: 0;
    z-index: 9;
    transition: 0.5s all;
  }

  .german .second-img {
    position: absolute;
    opacity: 0.7;
    bottom: -487px;
    left: 0;
    right: 0;
    z-index: 8;
    transition: 0.5s all;
  }

  .german .third-img {
    position: absolute;
    opacity: 0.6;
    bottom: -313px;
    left: 0;
    right: 0;
    z-index: 7;
    transition: 0.5s all;
  }

  .german .forth-img {
    position: absolute;
    opacity: 0.7;
    bottom: -124px;
    left: 0;
    right: 0;
    z-index: 6;
    transition: 0.5s all;
  }

  .german .first-hoverimg {
    opacity: 1;
    bottom: -98px;
    transition: 0.5s all;
    z-index: 13;
  }
  .german .second-hoverimg {
    opacity: 1;
    bottom: -126px;
    transition: 0.5s all;
    z-index: 12;
  }
  .german .third-hoverimg {
    opacity: 1;
    bottom:-90px;
    transition: 0.5s all;
    z-index: 11;
  }
  .german .forth-hoverimg {
    opacity: 1;
    bottom: -40px;
    transition: 0.5s all;
    z-index: 10;
  }
  .the-benefits.german .benefits-list .card-img-wrap {
    position: relative;
    padding-top: 60px;
  }

  /* italian */

  .italian .first-hoverimg:after,
  .italian .second-hoverimg:after,
  .italian .third-hoverimg:after,
  .italian .forth-hoverimg:after {
    content: "";
    background: #000000;
    width: 50%;
    height: 20%;
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 120px;
    transform: skewX(350deg);
    filter: blur(43px);
    z-index: -1;
  }
  .italian .first-img {
    position: absolute;
    opacity: 0.8;
    bottom: -557px;
    left: 0;
    right: 0;
    z-index: 9;
    transition: 0.5s all;
  }

  .italian .second-img {
    position: absolute;
    opacity: 0.7;
    bottom: -457px;
    left: 0;
    right: 0;
    z-index: 8;
    transition: 0.5s all;
  }

  .italian .third-img {
    position: absolute;
    opacity: 0.6;
    bottom: -326px;
    left: 0;
    right: 0;
    z-index: 7;
    transition: 0.5s all;
  }

  .italian .forth-img {
    position: absolute;
    opacity: 0.7;
    bottom: -164px;
    left: 0;
    right: 0;
    z-index: 6;
    transition: 0.5s all;
  }

  .italian .first-hoverimg {
    opacity: 1;
    bottom: -129px;
    transition: 0.5s all;
    z-index: 13;
  }
  .italian .second-hoverimg {
    opacity: 1;
    bottom: -128px;
    transition: 0.5s all;
    z-index: 12;
  }
  .italian .third-hoverimg {
    opacity: 1;
    bottom: -103px;
    transition: 0.5s all;
    z-index: 11;
  }
  .italian .forth-hoverimg {
    opacity: 1;
    bottom: -43px;
    transition: 0.5s all;
    z-index: 10;
  }
  .the-benefits.italian .benefits-list .card-img-wrap {
    position: relative;
    padding-top: 40px;
  }
}
@media screen and (max-width: 1199px) {
  .english .first-hoverimg:after,
  .english .second-hoverimg:after,
  .english .third-hoverimg:after,
  .english .forth-hoverimg:after {
    content: "";
    background: #000000;
    width: 50%;
    height: 20%;
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 120px;
    transform: skewX(350deg);
    filter: blur(43px);
    z-index: -1;
  }
  .english .first-img {
    position: absolute;
    opacity: 0.8;
    bottom: -513px;
    left: 0;
    right: 0;
    z-index: 10;
    transition: 0.5s all;
  }
  
  .english .second-img {
    position: absolute;
    opacity: 0.7;
    bottom: -412px;
    left: 0;
    right: 0;
    z-index: 7;
    transition: 0.5s all;
  }
  
  .english .third-img {
    position: absolute;
    opacity: 0.6;
    bottom: -262px;
    left: 0;
    right: 0;
    z-index: 8;
    transition: 0.5s all;
  }
  
  .english .forth-img {
    position: absolute;
    opacity: 0.7;
    bottom: -81px;
    left: 0;
    right: 0;
    z-index: 6;
    transition: 0.5s all;
  }
  
  .english .first-hoverimg {
    opacity: 1;
    bottom: -80px;
    transition: 0.5s all;
    z-index: 13;
  }
  .english .second-hoverimg {
    opacity: 1;
    bottom: -98px;
    transition: 0.5s all;
    z-index: 12;
  }
  .english .third-hoverimg {
    opacity: 1;
    bottom: -65px;
    transition: 0.5s all;
    z-index: 11;
  }
  .english .forth-hoverimg {
    opacity: 1;
    bottom: 0px;
    transition: 0.5s all;
    z-index: 10;
  }
  .the-benefits .benefits-list .card-img-wrap {
    position: relative;
    padding-top: 50px;
  }
  /* French */

  .french .first-hoverimg:after,
  .french .second-hoverimg:after,
  .french .third-hoverimg:after,
  .french .forth-hoverimg:after {
    content: "";
    background: #000000;
    width: 50%;
    height: 20%;
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 120px;
    transform: skewX(350deg);
    filter: blur(43px);
    z-index: -1;
  }

  .french .first-img {
    position: absolute;
    opacity: 0.8;
    bottom: -597px;
    left: 0;
    right: 0;
    z-index: 9;
    transition: 0.5s all;
  }

  .french .second-img {
    position: absolute;
    opacity: 0.7;
    bottom: -466px;
    left: 0;
    right: 0;
    z-index: 8;
    transition: 0.5s all;
  }

  .french .third-img {
    position: absolute;
    opacity: 0.6;
    bottom: -303px;
    left: 0;
    right: 0;
    z-index: 7;
    transition: 0.5s all;
  }

  .french .forth-img {
    position: absolute;
    opacity: 0.7;
    bottom: -109px;
    left: 0;
    right: 0;
    z-index: 6;
    transition: 0.5s all;
  }

  .french .first-hoverimg {
    opacity: 1;
    bottom: -88px;
    transition: 0.5s all;
    z-index: 13;
  }
  .french .second-hoverimg {
    opacity: 1;
    bottom: -74px;
    transition: 0.5s all;
    z-index: 12;
  }
  .french .third-hoverimg {
    opacity: 1;
    bottom: -29px;
    transition: 0.5s all;
    z-index: 11;
  }
  .french .forth-hoverimg {
    opacity: 1;
    bottom: 49px;
    transition: 0.5s all;
    z-index: 10;
  }
  /* German */

  .german .first-hoverimg:after,
  .german .second-hoverimg:after,
  .german .third-hoverimg:after,
  .german .forth-hoverimg:after {
    content: "";
    background: #000000;
    width: 50%;
    height: 20%;
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 120px;
    transform: skewX(350deg);
    filter: blur(43px);
    z-index: -1;
  }
  .german .first-img {
    position: absolute;
    opacity: 0.8;
    bottom:-654px;
    left: 0;
    right: 0;
    z-index: 9;
    transition: 0.5s all;
  }

  .german .second-img {
    position: absolute;
    opacity: 0.7;
    bottom:-525px;
    left: 0;
    right: 0;
    z-index: 8;
    transition: 0.5s all;
  }

  .german .third-img {
    position: absolute;
    opacity: 0.6;
    bottom:-333px;
    left: 0;
    right: 0;
    z-index: 7;
    transition: 0.5s all;
  }

  .german .forth-img {
    position: absolute;
    opacity: 0.7;
    bottom: -109px;
    left: 0;
    right: 0;
    z-index: 6;
    transition: 0.5s all;
  }

  .german .first-hoverimg {
    opacity: 1;
    bottom:-160px;
    transition: 0.5s all;
    z-index: 13;
  }
  .german .second-hoverimg {
    opacity: 1;
    bottom: -154px;
    transition: 0.5s all;
    z-index: 12;
  }
  .german .third-hoverimg {
    opacity: 1;
    bottom: -81px;
    transition: 0.5s all;
    z-index: 11;
  }
  .german .forth-hoverimg {
    opacity: 1;
    bottom: 23px;
    transition: 0.5s all;
    z-index: 10;
  }

  /* italian */

  .italian .first-hoverimg:after,
  .italian .second-hoverimg:after,
  .italian .third-hoverimg:after,
  .italian .forth-hoverimg:after {
    content: "";
    background: #000000;
    width: 50%;
    height: 20%;
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 120px;
    transform: skewX(350deg);
    filter: blur(43px);
    z-index: -1;
  }
  .italian .first-img {
    position: absolute;
    opacity: 0.8;
    bottom: -597px;
    left: 0;
    right: 0;
    z-index: 9;
    transition: 0.5s all;
  }

  .italian .second-img {
    position: absolute;
    opacity: 0.7;
    bottom: -466px;
    left: 0;
    right: 0;
    z-index: 8;
    transition: 0.5s all;
  }

  .italian .third-img {
    position: absolute;
    opacity: 0.6;
    bottom: -303px;
    left: 0;
    right: 0;
    z-index: 7;
    transition: 0.5s all;
  }

  .italian .forth-img {
    position: absolute;
    opacity: 0.7;
    bottom: -109px;
    left: 0;
    right: 0;
    z-index: 6;
    transition: 0.5s all;
  }

  .italian .first-hoverimg {
    opacity: 1;
    bottom: -128px;
    transition: 0.5s all;
    z-index: 13;
  }
  .italian .second-hoverimg {
    opacity: 1;
    bottom: -114px;
    transition: 0.5s all;
    z-index: 12;
  }
  .italian .third-hoverimg {
    opacity: 1;
    bottom: -69px;
    transition: 0.5s all;
    z-index: 11;
  }
  .italian .forth-hoverimg {
    opacity: 1;
    bottom: 9px;
    transition: 0.5s all;
    z-index: 10;
  }
}
@media screen and (max-width: 991px) {
  .the-benefits .benefits-list .card-img-wrap {
    padding-top: 110px;
  }

  /* .benefits-direction {
    flex-direction: column-reverse;
    display: flex;
  } */
  .english .first-hoverimg:after,
  .english .second-hoverimg:after,
  .english .third-hoverimg:after,
  .english .forth-hoverimg:after {
    content: "";
    background: #000000;
    width: 50%;
    height: 20%;
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 120px;
    transform: skewX(350deg);
    filter: blur(43px);
    z-index: -1;
  }
  .english .first-img {
    position: absolute;
    opacity: 0.8;
    bottom: -521px;
    left: 0;
    right: 0;
    z-index: 10;
    transition: 0.5s all;
  }
  
  .english .second-img {
    position: absolute;
    opacity: 0.7;
    bottom: -412px;
    left: 0;
    right: 0;
    z-index: 7;
    transition: 0.5s all;
  }
  
  .english .third-img {
    position: absolute;
    opacity: 0.6;
    bottom: -262px;
    left: 0;
    right: 0;
    z-index: 8;
    transition: 0.5s all;
  }
  
  .english .forth-img {
    position: absolute;
    opacity: 0.7;
    bottom: -81px;
    left: 0;
    right: 0;
    z-index: 6;
    transition: 0.5s all;
  }
  
  .english .first-hoverimg {
    opacity: 1;
    bottom: -80px;
    transition: 0.5s all;
    z-index: 13;
  }
  .english .second-hoverimg {
    opacity: 1;
    bottom: -98px;
    transition: 0.5s all;
    z-index: 12;
  }
  .english .third-hoverimg {
    opacity: 1;
    bottom: -65px;
    transition: 0.5s all;
    z-index: 11;
  }
  .english .forth-hoverimg {
    opacity: 1;
    bottom: 0px;
    transition: 0.5s all;
    z-index: 10;
  }
  /* French */

  .french .first-hoverimg:after,
  .french .second-hoverimg:after,
  .french .third-hoverimg:after,
  .french .forth-hoverimg:after {
    content: "";
    background: #000000;
    width: 50%;
    height: 20%;
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 120px;
    transform: skewX(350deg);
    filter: blur(43px);
    z-index: -1;
  }

  .french .first-img {
    position: absolute;
    opacity: 0.8;
    bottom: -538px;
    left: 0;
    right: 0;
    z-index: 9;
    transition: 0.5s all;
  }

  .french .second-img {
    position: absolute;
    opacity: 0.7;
    bottom: -429px;
    left: 0;
    right: 0;
    z-index: 8;
    transition: 0.5s all;
  }

  .french .third-img {
    position: absolute;
    opacity: 0.6;
    bottom: -282px;
    left: 0;
    right: 0;
    z-index: 7;
    transition: 0.5s all;
  }

  .french .forth-img {
    position: absolute;
    opacity: 0.7;
    bottom: -102px;
    left: 0;
    right: 0;
    z-index: 6;
    transition: 0.5s all;
  }
  .french .first-hoverimg {
    opacity: 1;
    bottom: -98px;
    transition: 0.5s all;
    z-index: 9;
  }
  .french .second-hoverimg {
    opacity: 1;
    bottom: -100px;
    transition: 0.5s all;
    z-index: 8;
  }
  .french .third-hoverimg {
    opacity: 1;
    bottom: -65px;
    transition: 0.5s all;
    z-index: 7;
  }
  .french .forth-hoverimg {
    opacity: 1;
    bottom: 0px;
    transition: 0.5s all;
    z-index: 6;
  }
  /* German */
  .german .first-hoverimg:after,
  .german .second-hoverimg:after,
  .german .third-hoverimg:after,
  .german .forth-hoverimg:after {
    content: "";
    background: #000000;
    width: 50%;
    height: 20%;
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 120px;
    transform: skewX(350deg);
    filter: blur(43px);
    z-index: -1;
  }
  .german .first-img {
    position: absolute;
    opacity: 0.8;
    bottom: -608px;
    left: 0;
    right: 0;
    z-index: 9;
    transition: 0.5s all;
  }

  .german .second-img {
    position: absolute;
    opacity: 0.7;
    bottom:-479px;
    left: 0;
    right: 0;
    z-index: 8;
    transition: 0.5s all;
  }

  .german .third-img {
    position: absolute;
    opacity: 0.6;
    bottom: -302px;
    left: 0;
    right: 0;
    z-index: 7;
    transition: 0.5s all;
  }

  .german .forth-img {
    position: absolute;
    opacity: 0.7;
    bottom: -109px;
    left: 0;
    right: 0;
    z-index: 6;
    transition: 0.5s all;
  }
  .german .first-hoverimg {
    opacity: 1;
    bottom: -120px;
    transition: 0.5s all;
    z-index: 9;
  }
  .german .second-hoverimg {
    opacity: 1;
    bottom: -130px;
    transition: 0.5s all;
    z-index: 8;
  }
  .german .third-hoverimg {
    opacity: 1;
    bottom: -90px;
    transition: 0.5s all;
    z-index: 7;
  }
  .german .forth-hoverimg {
    opacity: 1;
    bottom: -34px;
    transition: 0.5s all;
    z-index: 6;
  }

  /* italian */

  .italian .first-hoverimg:after,
  .italian .second-hoverimg:after,
  .italian .third-hoverimg:after,
  .italian .forth-hoverimg:after {
    content: "";
    background: #000000;
    width: 50%;
    height: 20%;
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 120px;
    transform: skewX(350deg);
    filter: blur(43px);
    z-index: -1;
  }
  .italian .first-img {
    position: absolute;
    opacity: 0.8;
    bottom: -538px;
    left: 0;
    right: 0;
    z-index: 9;
    transition: 0.5s all;
  }

  .italian .second-img {
    position: absolute;
    opacity: 0.7;
    bottom: -437px;
    left: 0;
    right: 0;
    z-index: 8;
    transition: 0.5s all;
  }

  .italian .third-img {
    position: absolute;
    opacity: 0.6;
    bottom: -289px;
    left: 0;
    right: 0;
    z-index: 7;
    transition: 0.5s all;
  }

  .italian .forth-img {
    position: absolute;
    opacity: 0.7;
    bottom: -109px;
    left: 0;
    right: 0;
    z-index: 7;
    transition: 0.5s all;
  }
  .italian .first-hoverimg {
    opacity: 1;
    bottom: -125px;
    transition: 0.5s all;
    z-index: 9;
  }
  .italian .second-hoverimg {
    opacity: 1;
    bottom: -140px;
    transition: 0.5s all;
    z-index: 8;
  }
  .italian .third-hoverimg {
    opacity: 1;
    bottom: -110px;
    transition: 0.5s all;
    z-index: 7;
  }
  .italian .forth-hoverimg {
    opacity: 1;
    bottom: -54px;
    transition: 0.5s all;
    z-index: 6;
  }
}
@media (max-width: 767px) {
  .header .menu-bar {
    justify-content: center;
  }
  .the-benefits .benefits-list .card-benefits {
    text-align: center;
  }
  .the-benefits .benefits-list {
    position: relative;
    display: flex;
    align-items: center;
    background: #253346;
    margin: 20px 0;
    border-radius: 20px;
    overflow: hidden;
    padding: 20px;
  }
  .first-img,
  .second-img,
  .third-img,
  .forth-img {
    position: relative !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0;
    right: 0;
  }
  .first-img:after,
  .second-img:after,
  .third-img:after,
  .forth-img:after {
    content: "";
    background: #ffffff;
    width: 50%;
    height: 20%;
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 120px;
    transform: skewX(350deg);
    filter: blur(43px);
    z-index: -1;
  }
}
@media (max-width: 576px) {
  .hero-switch-css {
    margin-top: 48px !important;
  }
}
.gk-accordion .accordion-item,
.gk-accordion .accordion-item .accordion-body {
  border: none;
  outline: none;
  background-color: #f4f4f4;
  border-radius: none;
  -webkit-border-radius: none;
  -moz-border-radius: none;
  -ms-border-radius: none;
  -o-border-radius: none;
}
.gk-accordion .accordion-item:first-of-type .accordion-button,
.gk-accordion .accordion-item:last-of-type .accordion-button.collapsed,
.gk-accordion .accordion-item h2 button:focus,
.gk-accordion .accordion-item:last-of-type .accordion-button.collapsed:focus {
  box-shadow: none;
  border-left: none;
  border-right: none;
  border-bottom: none;
  outline: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}
.gk-accordion .accordion-button:not(.collapsed) {
  background-color: #f4f4f4;
}
.gk-accordion .accordion-item h2 button::after {
  display: none;
}
.gk-accordion .accordion-item .accordion-body,
.gk-accordion .accordion-item h2 {
  background-color: #f4f4f4;
  border-bottom: none;
  transition: all 0.5s linear;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
}
.gk-accordion .accordion-item .accordion-body {
  padding: 0 10px 32px 0;

  color: #0d2240;
  font-family: "helvetica-light", sans-serif;
  font-size: 16px;
  line-height: 18px;
}
.gk-accordion .accordion-item h2 {
  padding: 0;
}
.gk-accordion .accordion-item h2 {
  margin-bottom: 0;
}
.gk-accordion .accordion-item h2 button {
  display: block;
  width: 100%;
  padding: 16px 60px 4px 0;
  text-align: left;

  color: #0d2240;
  font-family: "PT Serif", serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  border-top: 1px solid #0d2240;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
}
.gk-accordion .accordion-item h2 button::before {
  content: "";
  width: 12px;
  height: 12px;
  background: url("./images/close-icon.svg") center no-repeat;
  position: absolute;
  top: 0;
  right: 38px;
  bottom: 0;
  margin: auto 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -ms-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
}
.gk-accordion .accordion-item h2 button[aria-expanded="false"] {
  padding-bottom: 16px;
}
.gk-accordion .accordion-item:last-child h2 button[aria-expanded="false"] {
  border-bottom: 1px solid #0d2240;
}
.gk-accordion .accordion-item:last-child h2 button[aria-expanded="true"] {
  border-bottom: none;
}
.gk-accordion .accordion-item h2 button[aria-expanded="true"] {
  padding-bottom: 10px;
}
.gk-accordion .accordion-item h2 button[aria-expanded="true"]::before {
  opacity: 1;
  visibility: visible;
}
.gk-accordion .accordion-item h2 button {
  font-size: 18px;
  line-height: 26px;
  padding: 10px 30px 4px 0px;
}
.gk-accordion .accordion-item .accordion-body {
  padding: 0 10px 20px 0;
}
.gk-accordion .accordion-item h2 button[aria-expanded="false"] {
  padding-bottom: 10px;
}
.gk-accordion .accordion-item h2 button::before {
  right: 10px;
}
.gk-accordion.accordion {
  --bs-accordion-bg: transparent;
}
.languagebutton {
  background-color: transparent;
  color: white;
}

.hero-switch-css {
  margin-top: 0px !important;
}

@media (max-width: 576px) {
  .hero-switch-css {
    margin-top: 48px !important;
  }
}